<template>
    <div class="ownerQuit">
<!--        <van-nav-bar title="业主退租" left-arrow @click-left="leftReturn"/>-->
        <rxNavBar :title="detailTitle"></rxNavBar>
        <div class="applyTime">申请时间：&nbsp;{{totalInfos.applicationTime}}</div>
        <div class="text">
            <div class="complaint_message">
                <div class="wait-approve">{{approvalStatus}}</div>
                <div class="comlaint_site">
                    <div class="site">{{contractDetails.houseAddress}}</div>
                </div>
                <table  class="houseInfo">
                    <tr>
                        <td><div class="boldFont">编号：<span class="normalFont">{{contractDetails.contractCode}}</span></div></td>
                        <td><div class="boldFont">到期日：<span class="normalFont" style="color: #EB7B65;">{{totalInfos.oldContractEndTime}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont">{{type==2?'业主姓名':'租客姓名'}}：<span class="normalFont" >{{type==2?ownerContractInfo.ownerName:renterContractInfo.renterName}}</span></div></td>
                        <td><div class="boldFont">付款方式：<span class="normalFont" >{{totalInfos.oldPayTypeName}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont">租金：<span class="normalFont">{{type==2?ownerContractInfo.rentMoney:renterContractInfo.rentMoney}}元</span></div></td>
                        <td><div class="boldFont">押金：<span class="normalFont">{{type==2?ownerContractInfo.depositMoney:renterContractInfo.depositMoney}}元</span></div></td>
                    </tr>
                </table>
                <div>
                    <van-button plain type="primary" @click="gotoOldContractInfo">查看合同</van-button>
                </div>
            </div>
            <!--退房详情-->
            <div class="accept" >退房详情</div>
            <div class="complaint_message">
                <table  class="houseInfo">
                    <tr>
                        <td><div class="boldFont">正常退租：<span class="normalFont" style="color: #EB7B65;">{{historyInfo.isNormalReturn=='0'?'否':'是'}}</span></div></td>
                        <td><div class="boldFont">解除日期：<span class="normalFont">{{totalInfos.estimatedDepartureTime}}</span></div></td>
                    </tr>
                    <tr>
                        <td v-if="type==2"><div class="boldFont">违约方：<span class="normalFont" >{{!historyInfo.defaultingParty?'暂无':historyInfo.defaultingParty=='0'?'平台':'业主'}}</span></div></td>
                        <td v-if="type==1"><div class="boldFont">违约方：<span class="normalFont" >{{!historyInfo.defaultingParty?'暂无':historyInfo.defaultingParty=='0'?'平台':'租客'}}</span></div></td>
                        <td><div class="boldFont">有责：<span class="normalFont" style="color: #EB7B65;">{{historyInfo.defaultingType=='0'?'无':'有'}}</span></div></td>
                    </tr>
                </table>
                <div class="relieve">
                    <tr>
                        <td><div class="boldFont2" >解除类型：<span class="normalFont">{{totalInfos.contractTerminationTypeVal}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont2" >解除原因：<span class="normalFont1">{{totalInfos.contractTerminationReasonVal?totalInfos.contractTerminationReasonVal:'暂无'}}</span></div></td>
                    </tr>
                </div>
            </div>
            <!--退款详情-->
            <div class="accept"  >退款详情</div>
            <div class="complaint_message">
                <table  class="houseInfo" v-if="type==1">
                    <tr>
                        <td><div class="boldFont1">剩余租金：<span class="normalFont3" >{{historyInfo.restRentMoney == undefined ? '暂无' : historyInfo.restRentMoney+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">剩余水电：<span class="normalFont3" > {{historyInfo.restWaterElectric == undefined ? '暂无' : historyInfo.restWaterElectric+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">剩余押金：<span class="normalFont3" > {{historyInfo.surplusRent == undefined ? '暂无' : historyInfo.surplusRent+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">违约金：<span class="normalFont3" >{{historyInfo.falsify == undefined ? '暂无' : historyInfo.falsify+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">滞纳金：<span class="normalFont3" >{{historyInfo.lateFee == undefined ? '暂无' : historyInfo.lateFee+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">装修赔偿：<span class="normalFont3" >{{historyInfo.payRepair == undefined ? '暂无' : historyInfo.payRepair+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">预留水电费：<span class="normalFont3" >{{historyInfo.reserveWaterElectric == undefined ? '暂无' : historyInfo.reserveWaterElectric+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">利息：<span class="normalFont3" >{{historyInfo.interest == undefined ? '暂无' : historyInfo.interest+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1" >租客是否补款<span style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?'(无需补款)':'(需补款)'}}</span>：<span class="normalFont3" style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?'否':historyInfo.userPayedMoney == undefined ? '暂无' : historyInfo.userPayedMoney+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">实际违约金：<span class="normalFont3" >{{historyInfo.realFalsify == undefined ? '暂无' : historyInfo.realFalsify+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">应退总额：<span class="normalFont3" >{{historyInfo.totalRefunded == undefined ? '暂无' : historyInfo.totalRefunded+'元'}}</span></div></td>
                    </tr>
                </table>
                <table  class="houseInfo" v-if="type==2">
                    <tr>
                        <td><div class="boldFont1">业主退回房租：<span class="normalFont3" >{{historyInfo.restRentMoney == undefined ? '暂无' : historyInfo.restRentMoney+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">支付业主房租：<span class="normalFont3" > {{historyInfo.surplusRent == undefined ? '暂无' : historyInfo.surplusRent+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">违约金：<span class="normalFont3" >{{historyInfo.realFalsify == undefined ? '暂无' : historyInfo.realFalsify+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">装修赔偿：<span class="normalFont3" >{{historyInfo.payRepair == undefined ? '暂无' : historyInfo.payRepair+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">公司退回总额：<span class="normalFont3" >{{historyInfo.userTotalPay == undefined ? '暂无' : historyInfo.userTotalPay+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">业主退回总额：<span class="normalFont3" >{{historyInfo.realBreakMoney == undefined ? '暂无' : historyInfo.realBreakMoney+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont-remarks">补充说明：<span class="normalFont-remarks" >{{historyInfo.remarks}}</span></div></td>
                    </tr>
                </table>
<!--                <table  class="houseInfo">-->
<!--                    <tr>-->
<!--                        <td><div class="boldFont1" >退回总额<span style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?'':'(需补款)'}}</span>：<span class="normalFont7" style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?historyInfo.userTotalPay:historyInfo.UserPayedMoney}}元</span></div></td>-->
<!--                    </tr>-->
<!--                </table>-->
                <div>
                    <van-button class="look" plain type="primary" @click="openElectricContract">查看解除协议</van-button>
                </div>
            </div>
            <div v-if="historyType=='换房' || historyType=='转租'">
                <div class="accept"  >新房信息</div>
                <div class="complaint_message">
                    <div class="comlaint_site">
                        <div class="site">{{totalInfos.newContractAddress}}</div>
                    </div>
                    <table  class="houseInfo">
                        <tr>
                            <td><div class="boldFont">合同编号：<span class="normalFont">{{totalInfos.newContractCode}}</span></div></td>
                            <td><div class="boldFont">付款方式：<span class="normalFont" >{{totalInfos.newPayTypeName}}</span></div></td>
                        </tr>
                        <tr>
                            <td><div class="boldFont">租客姓名：<span class="normalFont" >{{newMiddlecontract.renterName}}</span></div></td>
                            <td><div class="boldFont">押金：<span class="normalFont">{{newMiddlecontract.depositMoney}}元</span></div></td>
                        </tr>
                        <tr>
                            <td><div class="boldFont">租金：<span class="normalFont">{{newMiddlecontract.rentMoney}}元</span></div></td>
                        </tr>
                        <tr>
                            <td colspan="2"><div class="boldFont-1">租期：<span class="normalFont" style="color: #EB7B65;">{{totalInfos.newContractBeginTime+' 至 '+totalInfos.newContractEndTime}}</span></div></td>
                        </tr>
                    </table>
                    <div>
                        <van-button plain type="primary" @click="gotoNewContractInfo">查看合同</van-button>
                    </div>
                </div>
            </div>
            <!--审核反馈-->
            <div class="accept">审核反馈</div>
            <div class="bottom-check" v-for="(item,index) in approvalHistoryInfoList" :key="index">
                <div class="bottom-text">
                    <span class="bottom-text-left">审核人：</span>
                    <span class="bottom-text-right">{{item.approvalStaffName}}</span>
                </div>
                <div class="bottom-text">
                    <span class="bottom-text-left">审核时间：</span>
                    <span class="bottom-text-right">{{item.addTime}}</span>
                </div>
                <div class="bottom-text" v-if="item.approvalResult=='0'">
                    <span class="bottom-text-left ">审核结果：</span>
                    <span class="bottom-text-right text-green">{{item.approvalResultStr}}</span>
                </div>
                <div class="bottom-text" v-if="item.approvalResult=='1'">
                    <span class="bottom-text-left">审核结果：</span>
                    <span class="bottom-text-right text-red">{{item.approvalResultStr}}</span>
                </div>
                <div class="bottom-text" v-if="item.approvalResult=='2'">
                    <span class="bottom-text-left">审核结果：</span>
                    <span class="bottom-text-right">{{item.approvalResultStr}}</span>
                </div>
                <div class="bottom-text" v-if="item.approvalResult=='1'">
                    <span class="bottom-text-left">驳回原因：</span>
                    <span class="bottom-text-right">{{item.dictionaryTitle}}</span>
                </div>
                <div class="bottom-text">
                    <span class="bottom-text-left">审核意见：</span>
                    <span class="bottom-text-right">{{item.remarks?item.remarks:'暂无'}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { NavBar,Search, DropdownMenu, DropdownItem ,Button,Toast,} from 'vant';
    import {approvalContractDetail, findElectronicContractUrl, renterRetreatDetails} from "../../../getData/getData";
    import {checkAndroid, checkIOS, openInWebview, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    export default {
        components: {
            [Button.name]: Button,
            [NavBar .name]:NavBar,
            [Search .name]:Search,
            [DropdownMenu .name]:DropdownMenu,
            [DropdownItem .name]:DropdownItem,
            [Toast.name]:Toast,
            rxNavBar
        },
        name: "ownerQuitDetails",
        methods: {
            //返回上一层
            leftReturn() {
                this.$router.go(-1)
            },
            //业主或者租客解除合同信息初始化加载
            approvalContractDetail(){
                //先初始化字典信息
                //this.initReasonAndTypeData()
                let that = this
                let initData={}
                initData.contractHistoryId = that.ch_id
                approvalContractDetail(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data)
                        that.totalInfos = response.data.data
                        if(that.type==2){
                            //业主合同信息
                            that.ownerContractInfo = that.totalInfos.ownercontract
                            let historyContractType = response.data.data.contracthistory.contractHistoryHistoryType
                            //根据type和contractHistoryHistoryType动态改变标题
                            that.checkTitle(that.type,historyContractType)
                        } else {
                            //租客合同信息
                            that.renterContractInfo = that.totalInfos.rentercontract
                            let historyContractType = response.data.data.contracthistory.contractHistoryHistoryType
                            //根据type和contractHistoryHistoryType动态改变标题
                            that.checkTitle(that.type,historyContractType)
                        }
                        that.historyInfo = that.totalInfos.contracthistory
                        that.contractDetails = that.totalInfos.contract
                        that.newMiddlecontract = that.totalInfos.newMiddlecontract
                        that.newContract = that.totalInfos.newContract
                    })
                })
            },
            //动态改变导航标题方法
            checkTitle(type,historyContractType){
                let firstText= '';
                let secondText = '';
                if(type == 2){
                    firstText = '业主';
                }else{
                    firstText = '租客';
                }
                if(historyContractType == 1){
                    secondText = '续签';
                }else if(historyContractType == 2 || historyContractType == 3){
                    secondText = '退房';
                }else if(historyContractType == 4){
                    secondText = '转租';
                }else if(historyContractType == 5){
                    secondText = '换房';
                }
                this.detailTitle = firstText + secondText + '详情';
            },
            //跳转合同详情页面
            gotoOldContractInfo(){
                this.$router.push({
                    name:'contractDetail',
                    query:{
                        contractId:this.contractId,
                        type:this.type,
                        ownerOrRenterContractId:this.type==2?this.ownerContractInfo.id:this.renterContractInfo.id,
                        historyType:this.historyType,
                        contractTypeVal:this.getContractVal(this.contractStatus),
                        contractStatus:this.contractStatus,
                        mode:'1',
                        contractHistoryId:this.ch_id ||''
                    }
                });
            },

            //跳转新合同详情页面
            gotoNewContractInfo(){
                this.$router.push({
                    name:'contractDetail',
                    query:{
                        contractId:this.newContract.id,
                        type:this.type,
                        ownerOrRenterContractId:this.newMiddlecontract.id,
                        historyType:this.historyType,
                        contractTypeVal:this.getContractVal(this.newContract.contractStatus),
                        contractStatus:this.newContract.contractStatus,
                        mode:'1',
                        contractHistoryId:''
                    }
                });
            },

            getContractVal(status){
                if(status==4){
                    return '已解除'
                } else if(status==5){
                    return '待签字'
                } else if(status==7){
                    return '已作废'
                } else {
                    return '租期中'
                }
            },

            //查看电子合同
            openElectricContract(){
                let that = this
                if (that.contractDetails.electricRelieveContract_id) {
                    let queryData = {};
                    queryData.contractId = that.contractDetails.electricRelieveContract_id;
                    findElectronicContractUrl(queryData).then(function(response) {
                        responseUtil.dealResponse(that, response, () => {
                            if (openInWebview()) {
                                if(checkAndroid()){
                                    window.open(response.data.data.url);
                                }else if(checkIOS()){
                                    window.location.href =response.data.data.url;
                                }
                            }
                        })


                    })
                } else {
                    responseUtil.alertMsg(that, '没有电子合同！');
                }
            },

            //初始化审核状态信息(所有接口都一样,只需传入审批id即可)
            renterRetreatDetails(){
                let that = this
                let initData={}
                initData.id = that.approvalFinishId
                renterRetreatDetails(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data)
                        that.approvalHistoryInfoList = response.data.data.approvalHistoryInfoList || []
                        that.approvalStatus = response.data.data.item.approvalStatusStr
                        //console.log(that.id)
                    })
                })
            },


        },
        created() {
            this.contractId = this.$route.query.contractId
            this.ch_id = this.$route.query.ch_id
            this.id = this.$route.query.id
            this.lastRefuseCount = this.$route.query.lastRefuseCount
            this.lastRefuseReason = this.$route.query.lastRefuseReason
            this.type = this.$route.query.type
            this.historyType = this.$route.query.historyType
            this.contractTypeVal = this.$route.query.contractTypeVal
            this.contractStatus = this.$route.query.contractStatus
            this.mode = this.$route.query.mode
            this.approvalFinishId = this.$route.query.approvalFinishId
        },
        mounted(){
            this.approvalContractDetail()
            this.renterRetreatDetails()
        },
        data() {
            return {
                detailTitle: '',
                totalInfos:'',//总的信息
                ownerContractInfo:'',//业主合同信息
                renterContractInfo:'',//租客合同信息
                historyInfo:'',//合同历史信息
                contractDetails:'',//合同信息
                newContract:'',//新签的合同
                newMiddlecontract:'',//新签的(租客或者业主)合同
                number:-1,
                rejectReason:'',
                isReasonSelect: false,
                isPass:false,
                isReject:false,
                isSelect:'',
                isReasonShow:false,
                guidingPrice:"",
                //拒绝理由
                refuseReasonList:[],
                refuseTypeList:[],
                refuseType:'',
                refuseReason:'',
                contractInfo:'',
                checkRefuseReasonList:[],//审核拒绝原因
                applayApproveInfo:'',//申请详情
                approvalOpinion:'',//审批意见
                refuseReasonId:'',//拒绝理由的id
                dataList: {},
                contractId:'',
                ch_id:'',
                id:'',
                lastRefuseCount:'',
                lastRefuseReason:'',
                type:'',
                historyType:'',
                contractTypeVal:'',
                contractStatus:'',
                mode:'',
                approvalFinishId:'',
                approvalHistoryInfoList:[],//审核历史记录
                approvalStatus:'',//审核状态
            }
        }
    }
</script>

<style scoped>
    .ownerQuit{
        overflow: hidden;
        width: 100%;
    }
    .relieve{
        padding: 20px 0px 45px 15px;
    }
    .reject{
        padding-left: 30px;
        padding-top: 6px;
        width: 315px;
        height: 34px;
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .look{
        width: 120px;
        margin-left: 210px;
    }
    .wait-approve{
        width: fit-content;
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        text-align: center;
        padding-top: 2px;
        padding-right: 5px;
        padding-left: 5px;
        color: white;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffb96f,#ff6d44);
    }
    .applyTime{
        font-size: 12px;
        text-align: right;
        color: #999999;
        padding-right: 15px;
        margin-top: 16px;
        margin-bottom: 6px;
    }
    .site{

    }
    .comlaint_site{
        display: flex;
        height: 55px;
        padding: 5px 0px 10px 15px;
        font-size: 17px;
        border-bottom: 1px solid #EFEFEF ;
        font-weight: bold;
    }
    .complaint_message{
        width: 345px;
        border-radius: 8px;
        background-color: #FFFFFF;
    }
    .text{
        margin-left: 15px;
    }
    .accept{
        height: 17px;
        color: rgba(255, 93, 59, 1);
        font-size: 12px;
        margin-top: 30px;
        font-weight: bold;
        margin-bottom: 5px;
        font-family: PingFangSC-Semibold;
    }
    .houseInfo{
        width:345px;
        padding: 15px 10px 15px 10px;
        border-bottom: 1px solid #EFEFEF ;
    }

    .boldFont{
        font-size: 14px;
        font-weight: bold;
        width: 157px;
        height: 24px;
    }
    .boldFont-1{
        font-size: 14px;
        font-weight: bold;
        height: 24px;
    }
    .boldFont1{
        font-size: 14px;
        font-weight: bold;
        width: 309px;
        height: 24px;
    }
    .boldFont-remarks{
        font-size: 14px;
        font-weight: bold;
        width: 309px;
        height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .boldFont2{
        font-size: 14px;
        font-weight: bold;
        width: 315px;
        height: 24px;
    }
    .normalFont{
        font-size: 13px;
        font-weight: normal;
        color: #999;
    }
    .normalFont1{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        line-height: 25px;
    }
    .normalFont8{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 190px;

    }
    .normalFont2{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 186px;
    }
    .normalFont3{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        float: right;
    }
    .normalFont-remarks{
        font-size: 13px;
        font-weight: normal;
        color: #999;
    }
    .normalFont4{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 190px;
    }
    .normalFont5{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 204px;
    }
    .normalFont6{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 218px;
    }
    .normalFont7{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 125px;
    }
    .applyTime{
        font-size: 12px;
        text-align: right;
        color: #999999;
        padding-right: 15px;
        margin-top: 16px;
        margin-bottom: 6px;
    }
    .bottom-check{
        width: 345px;
        margin: 0 15px 15px 0;
        padding-top: 15px;
        padding-bottom: 15px;
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        position: relative;
        font-size: 15px;
        text-align: left;
        overflow: hidden;
    }
    .bottom-text{
        height: auto;
        line-height: 24px;
        margin-left: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .bottom-text-left{
        font-weight: bold;
    }
    .bottom-text-right{
        font-size: 14px;
        color: #999999;
    }
    .text-red{
        color: #FF7357;
    }
    .text-green{
        color: #4DC7AF;
    }

</style>
